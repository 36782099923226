import * as React from "react"
// import Layout from "../components/layout"
import Seo from "../components/seo"
import { FiAlertTriangle } from "react-icons/fi"

const ScoutMerch = () => {
  return (
    <div>
      <Seo title="Scout Vineyards -2022 Marquette Nouveau Collection" />
      <div
        className="bg-indigo-100 rounded-lg flex items-center gap-2 justify-center py-5 px-6 mb-4 text-xs text-indigo-700"
        role="alert"
      >
        <FiAlertTriangle />
        Orders placed after December 12, 2022 will be shipped in January 2022.
      </div>

      <div className="flex flex-col justify-center pt-4">
        <h1 className="text-2xl my-0 font-bold tracking-widest uppercase mt-4 mb-6 text-center">
          Marquette Nouveau Collection
        </h1>
        {/* Product 4 */}
        <section className="lg:flex md:flex-row flex-col justify-center items-center text-center mx-auto w-11/12">
          <img
            src="https://cdn.sanity.io/images/rbhjt84j/production/1a6c58a9cd20b3b4310321de825eea259d9f132a-2977x2972.jpg"
            alt="Vinification - Japanese Paper Cut"
            className="lg:w-1/2 rounded-lg shadow"
          />
          <div className="lg:p-4">
            <h1 className="text-2xl my-0 font-medium">Vinification</h1>
            <h5 className="text-xs tracking-widest my-0 font-medium">
              JAPANENSE PAPER CUT
            </h5>
            <p className="text-lg font-bold">$425.00</p>
            <button
              className="snipcart-add-item w-full md:w-auto flex-shrink-0 text-white mb-4 bg-[#900C3F] border-0 py-2 px-4 focus:outline-none hover:bg-[#ab0e4a] rounded font-bold tracking-widest text-xs uppercase "
              data-item-id="scout-vinification-paper-art"
              data-item-price="425.00"
              data-item-url="https://www.budmen.com/scout"
              data-item-max-quantity="10"
              data-item-description="Original artistic work by Stephanie and Isaac Budmen.Numbered and signed by the artists. 18in x 18in. Edition of 10."
              data-item-image="https://cdn.sanity.io/images/rbhjt84j/production/1a6c58a9cd20b3b4310321de825eea259d9f132a-2977x2972.jpg"
              data-item-name="Vinification - Japanese Paper Cut"
            >
              Add to cart
            </button>
            <p className="mb-0 lg:block hidden">
              Original artistic work by Stephanie and Isaac Budmen. An abstract
              reflection on the wine making process. Inspired by the shapes,
              sounds, material, and stages of Scout’s production this form
              meditates on the complexity, nuance and organic nature of turning
              fruit into wine.
            </p>
            <p className="mt-2 lg:block hidden">
              {" "}
              Numbered and signed by the artists. 18in x 18in. Edition of 10.{" "}
            </p>
          </div>
        </section>
        {/* Product 1 */}
        <section className="lg:flex md:flex-row flex-col justify-center items-center text-center mx-auto w-11/12">
          <img
            src="https://cdn.sanity.io/images/rbhjt84j/production/a49a661e4c27bccecba7976f02993782371ab761-4032x3024.jpg"
            alt="Farmhouse Coasters"
            className="lg:w-1/2 rounded-lg shadow"
          />
          <div className="lg:p-4">
            <h1 className="text-2xl my-0 font-medium">Cartographer's Block</h1>
            <p className="text-lg font-bold">$125.00</p>
            <button
              className="snipcart-add-item w-full md:w-auto flex-shrink-0 text-white mb-4 bg-[#900C3F] border-0 py-2 px-4 focus:outline-none hover:bg-[#ab0e4a] rounded font-bold tracking-widest text-xs uppercase "
              data-item-id="scout-cartographers-block"
              data-item-price="125.00"
              data-item-url="https://www.budmen.com/scout"
              data-item-description="Handmade walnut charcuterie board featuring the topograph of Scout Vineyards."
              data-item-image="https://cdn.sanity.io/images/rbhjt84j/production/a49a661e4c27bccecba7976f02993782371ab761-4032x3024.jpg"
              data-item-name="Cartographer's Block"
            >
              Add to cart
            </button>
            <p className="lg:block hidden">
              Featuring the topography of Scout Vineyards, this heirloom quality
              charcuterie board is handmade from a single piece of 3/4” American
              Walnut. Hand finished with a beautiful food-safe oil and polish.
            </p>
            <div className="flex text-gray-500 gap-2 uppercase mb-4 justify-center md:mx-0 tracking-widest text-[0.65em]">
              <span> Walnut | </span>
              <span>16 x 10 x 3/4in</span>
            </div>
          </div>
        </section>
        {/* Product 2 */}
        <section className="lg:flex md:flex-row flex-col justify-center items-center text-center mx-auto w-11/12">
          <img
            src="https://cdn.sanity.io/images/rbhjt84j/production/b13bc895a06a2f5072be7e0d8596a2990b887043-2867x3823.jpg?rect=0,350,2867,2867"
            alt="Press Coasters"
            className="lg:w-1/2 rounded-lg shadow"
          />
          <div className="lg:p-4">
            <h1 className="text-2xl my-0 font-medium">2022 Press Coasters</h1>
            <p className="text-lg font-bold">$35.00</p>
            <button
              className="snipcart-add-item w-full md:w-auto flex-shrink-0 text-white mb-4 bg-[#900C3F] border-0 py-2 px-4 focus:outline-none hover:bg-[#ab0e4a] rounded font-bold tracking-widest text-xs uppercase "
              data-item-id="scout-press-coasters"
              data-item-price="35.00"
              data-item-url="https://www.budmen.com/scout"
              data-item-description="Set of 4 coasters featuring the 2022 Scout Vineyards Press artwork."
              data-item-image="https://cdn.sanity.io/images/rbhjt84j/production/b13bc895a06a2f5072be7e0d8596a2990b887043-2867x3823.jpg?rect=0,350,2867,2867"
              data-item-name="2022 Press Coasters - Set of 4"
              data-item-custom1-name="Names for engraving (up to 4)"
              data-item-custom1-type="textarea"
            >
              Add to cart
            </button>
            <p className="lg:block hidden">
              An homage to Olivia and Daniel’s passion for the fruit they make
              their products from and their relentless commitment to craft, this
              4 piece coaster set features 4 varietals from Scout’s 2022
              vintage.
            </p>
            <div className="flex text-gray-500 gap-2 uppercase mb-4 justify-center md:mx-0 tracking-widest text-[0.65em]">
              <span>Set of 4 | </span>
              <span>Cherry | </span>
              <span>4in dia x 1/4in</span>
            </div>
          </div>
        </section>
        {/* Product 3 */}
        <section className="lg:flex md:flex-row flex-col justify-center items-center text-center mx-auto w-11/12">
          <img
            src="https://cdn.sanity.io/images/rbhjt84j/production/e77c1f0a460a5c42d1d5a079afa72a5fd45e8595-3024x4032.jpg?rect=0,450,3000,3000"
            alt="Farmhouse Coasters"
            className="lg:w-1/2 rounded-lg shadow"
          />
          <div className="lg:p-4">
            <h1 className="text-2xl my-0 font-medium">Farmhouse Coasters</h1>
            <p className="text-lg font-bold">$25.00</p>
            <button
              className="snipcart-add-item w-full md:w-auto flex-shrink-0 text-white mb-4 bg-[#900C3F] border-0 py-2 px-4 focus:outline-none hover:bg-[#ab0e4a] rounded font-bold tracking-widest text-xs uppercase "
              data-item-id="scout-farmhouse"
              data-item-url="https://www.budmen.com/scout"
              data-item-price="25.00"
              data-item-description="Set of 4 coasters featuring the an illustration of the Scout Farmhouse"
              data-item-image="https://cdn.sanity.io/images/rbhjt84j/production/e77c1f0a460a5c42d1d5a079afa72a5fd45e8595-3024x4032.jpg?rect=0,450,3000,3000"
              data-item-name="Farmhouse Coasters - Set of 4"
            >
              Add to cart
            </button>
            <p className="mb-0 lg:block hidden">
              For thousands of years, the farmhouse has provided shelter and a
              warm hearth for those who care for things that grow.
            </p>
            <p className="mt-0 lg:block hidden">
              {" "}
              This 4 piece coaster set is a tribute to the history of farmhouses
              everywhere.
            </p>
            <div className="flex text-gray-500 gap-2 uppercase mb-4 justify-center md:mx-0 tracking-widest text-[0.65em]">
              <span>Set of 4 | </span>
              <span>Birch | </span>
              <span>4in dia x 1/4in</span>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}
export default ScoutMerch
